export default function GifLoadingMobile() {
  return (
    <div className=" flex flex-col justify-center items-center">
      <img
        src="main.gif"
        alt="Demo of the Nyoko"
        className="gif-image"
        style={{ height: "55vh" }}
      />
    </div>
  );
}
