import React from "react";

const TextBody = ({ text, fontSize }) => {
  if (!fontSize) {
    fontSize = "2xl";
  }
  return (
    <div className={`text-white font-[montserrat] text-${fontSize} w-fit`}>
      <p>{text}</p>
    </div>
  );
};

export default TextBody;
